
import * as Components from "./Donuthut"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "donuthut"
}
