import React, { Component, PureComponent } from 'react';
import {NavLink} from 'react-router-dom'

import './index.scss'

import OurStoryPage from './OurStoryPage'
import Catering from './Catering'
import Banquet from './Banquet'
export {OurStoryPage as ourstory}
export {Catering as catering}
export {Banquet as banquet}

export class SiteNav extends PureComponent {
  render() {
    const pages = [
      {path: "/", label: "Order"},
      // {path: "/catering", label: "Catering"},
      // {path: "/banquet", label: "Banquet"},
      // {path: "http://eat24hrs.com/restaurants/order2/menu.php?id=27570", label: "Eat24", external: true },
      // {path: "/contact", label: "hours/loc"},
      // {path: "/our-sory", label: "Our Story"}

    ]
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external}) => (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
    </nav>
  )}
}



export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <div className="text">
            <div className="contact">


            </div>
            <div className="icon">
              <img src="https://res.cloudinary.com/dgps1radv/image/upload/c_scale,w_300/v1550616933/danstuff/Artboard_1donut-splash.png" alt=""/>
            </div>
            <h1>View Menu Below</h1>



          </div>


        </div>
      </div>
    );
  }
}


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (minutes >= 30) && (hours < 15)
  ) || (
    (hours >= 12) && (hours < 15)
  )
  const showLunchMenu = isWeekday && isLunch

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (isLunchSubmenu && showLunchMenu) { return true }
  if (isDinnerSubmenu && !showLunchMenu) { return true }

  return false
}
